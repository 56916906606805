import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Routes from 'routes';
import { useSelector, useDispatch } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import axios from 'axios';
import NavBar from 'components/Common/Header';
import Loader from 'components/Common/Loader';
import Api from 'api/app';
import { setCustomerId, setSubscriptionId, setSubscriptionRedux } from './state/actions/checkout';
import { SET_USER_DETAILS } from './state/types';
import { useAuth0 } from '@auth0/auth0-react';

import { setSubscriptionDetails } from './state/actions/checkout';
const API_BASE_URL = process.env.REACT_APP_API_URL;

const MainApp = () => {
  const state = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const { user, isLoading, isAuthenticated } = useAuth0();
  const history = useHistory();
  const getSubscription = async () => {
    const result = await axios.get(`${API_BASE_URL}/stripe/get-subscription?subscriptionId=${state.subscription?.subscriptionId}`);
    if (result?.data?.data) {
      dispatch(setSubscriptionDetails('subscriptionDetails', result.data.data));
    }
  };
  const login = async () => {
    try {
      const userData = await Api.login(user.email, user.name);
      console.log(userData);
      dispatch(
        setSubscriptionRedux(SET_USER_DETAILS, 'user', {
          ...user,
          ...userData.data
        })
      );
      if (userData.data?.customerId) {
        dispatch(setCustomerId('customerId', userData.data.customerId));
      }
      if (userData.data?.subscriptionId) {
        dispatch(setSubscriptionId('subscriptionId', userData.data.subscriptionId));
      }
      if (localStorage.getItem('priceId')) {
        if (userData.data?.customerId && userData.data?.subscriptionId) {
          history.push('/dashboard');
          localStorage.removeItem('priceId');
        } else {
          // history.push('/checkout/' + localStorage.getItem('priceId'));

          const response = await Api.checkoutSession(localStorage.getItem('priceId') || '', user.email);
          localStorage.removeItem('priceId');
          if (response.data.id) {
            history.push({
              pathname: '/checkout/' + response.data.id
            });
          }
          console.log(response);
        }
      }
    } catch (error) {
      setTimeout(() => login(), 5000);
    }
  };
  useEffect(() => {
    if (user && user.email) {
      login();
    }
    console.log('user', user);
  }, [user]);
  useEffect(() => {
    if (state.subscription?.subscriptionId) {
      getSubscription();
      // dispatch(setSubscriptionDetails('subscriptionDetails', {}));
    }
  }, [state.subscription?.subscriptionId]);
  return (
    <div>
      <LoadingOverlay active={isLoading} spinner>
        <Routes isAuthenticated={isAuthenticated} />
      </LoadingOverlay>
    </div>
  );
};

export default MainApp;
