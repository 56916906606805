import { watcherLoader } from 'state/sagas/app/loader';
import { takeLatest, call, all, put } from 'redux-saga/effects';
import axios from 'axios';
import { GET_REPORT, SET_REDUX_KEY, SET_SUBSCRIPTION_DETAILS } from 'state/types';
import { getQuestions } from 'data/questions';
import Api from 'api/app';

/// /////////// Handlers ///////////////////////
function* getReportHandler(action) {
  try {
    const questions = getQuestions(action.payload);

    const report = yield all(questions.map(question => call(Api.getReport, question)));
    const list = questions.map((ques, index) => ({ question: ques, answer: report[index].choices[0].text }));
    yield put({ type: GET_REPORT.FULLFILLED, payload: list });
    const updateUsage = yield call(Api.updateUsage, 1, action.subscriptionItemId, action.email);
    if (updateUsage?.data?.id && action.subscriptionItemId) {
      yield put({ type: SET_SUBSCRIPTION_DETAILS, payload: { key: 'dailyQuantity', value: 1 } });
      localStorage.setItem('dailyQuantity', '1');
      localStorage.setItem('lastDailyUsage', new Date().getTime().toString());
    }
    yield put({ type: SET_REDUX_KEY, payload: { key: 'activeTab', value: 'report' } });
  } catch (e) {}
}

/// /////////// Watchers ///////////////////////
export function* watcherDashboard() {
  yield takeLatest(GET_REPORT.STARTED, getReportHandler);
}

export const app = [watcherDashboard(), watcherLoader()];
