import { API_TYPE } from 'utils';

export interface IAction {
  type: string;
  payload?: any;
}

/* -------------------------------------------------------------------------- */
/*                                     APP                                    */
/* -------------------------------------------------------------------------- */
export const SET_REDUX_KEY = 'SET_REDUX_KEY';
export const CLEAR_REDUX = 'CLEAR_REDUX';

/* --------------------------------- LOADER --------------------------------- */
export const APP = API_TYPE('APP');
export const CLEAR_LOADING = 'CLEAR_LOADING';

export const GET_REPORT = API_TYPE('GET_REPORT');

export const SET_CUSTOMER_ID = 'SET_CUSTOMER_ID';
export const SET_SUBSCRIPTION_DETAILS = 'SET_SUBSCRIPTION_DETAILS';
export const SET_SUBSCRIPTION_ID = 'SET_SUBSCRIPTION_ID';
export const SET_USER_DETAILS = 'SET_USER_DETAILS';
