import React, { Suspense, lazy, useEffect } from 'react';
import map from 'lodash/map';
import { Switch, Redirect } from 'react-router-dom';
import PrivateRoute from 'routes/PrivateRoute';
import UserRoute from 'routes/UserRoute';
import { useAuth0 } from '@auth0/auth0-react';
import Navbar from 'components/Common/Header';

const API_BASE_URL = process.env.REACT_APP_API_URL;

const DashbaordRoutes = lazy(() => import('pages/Dashboard'));
const PricingRoutes = lazy(() => import('pages/Pricing'));
const CheckoutRoutes = lazy(() => import('pages/Checkout/index.js'));
const HomeRoutes = lazy(() => import('pages/Home'));
const CheckoutFailed = lazy(() => import('pages/CheckoutFailure'));
const CheckoutSuccess = lazy(() => import('pages/CheckoutSuccess'));
const ProfileRoute = lazy(() => import('pages/Profile'));

const RoutesHOC = (routes: any, DEFAULT_PATH: any, LANDING_PAGE: any) => {
  return function Component(props) {
    const { isAuthenticated } = props;
    return (
      <Suspense fallback={<div></div>}>
        <Switch>
          {map(routes, route => {
            // if (route.isPrivate) {
            //   return (
            //     <PrivateRoute
            //       key={route.title}
            //       title={route.title}
            //       path={route.path}
            //       component={route.component}
            //       defaultPath={DEFAULT_PATH}
            //     />
            //   );
            // }
            if (route.isPrivate && !isAuthenticated) {
              return <Redirect to={'/'} />;
            }
            // if (route.checkSubscriptionNotActive && !state.subscription?.subscriptionId) {
            //   return <Redirect to={'/pricing'} />;
            // }
            return (
              <UserRoute key={route.title} title={route.title} defaultPath={DEFAULT_PATH} path={route.path} component={route.component} />
            );
          })}
          <Redirect to={LANDING_PAGE} />
        </Switch>
      </Suspense>
    );
  };
};

export const dashboardRoutes = {};

export const routes = {
  DASHBOARD: {
    path: '/dashboard',
    title: 'Dashboard',
    component: DashbaordRoutes,
    isPrivate: false,
    checkSubscriptionNotActive: true
  },
  Pricing: {
    path: '/pricing',
    title: 'Pricing',
    component: PricingRoutes,
    isPrivate: false
  },
  Checkout: {
    path: '/checkout/:sessionId',
    title: 'Checkout',
    component: CheckoutRoutes,
    isPrivate: false,
    checkSubscription: true
  },
  Profile: {
    path: '/profile',
    title: 'Profile',
    component: ProfileRoute,
    isPrivate: false,
    checkSubscription: false
  },
  CheckoutFailure: {
    path: '/failed',
    title: 'Checkout Failed',
    component: CheckoutFailed,
    isPrivate: false,
    checkSubscription: false
  },
  CheckoutSuccess: {
    path: '/success',
    title: 'Checkout success',
    component: CheckoutSuccess,
    isPrivate: false,
    checkSubscription: false
  },
  Home: {
    path: '/',
    title: 'Home',
    component: HomeRoutes,
    isPrivate: false,
    checkSubscription: false
  }
};

const AppRoutes = RoutesHOC(routes, '/pricing', '/');
export default AppRoutes;

export const DEFAULT_PATH = '/pricing';
export const USER_LANDING_PAGE = '/';
