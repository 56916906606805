import { combineReducers } from 'redux';
import app from 'state/reducers/app';
import dashboard from 'state/reducers/dashboard';
import subscription from 'state/reducers/subscription';

export default combineReducers({
  app,
  dashboard,
  subscription
});
