const colors = {
  background: 'hsl(215, 27%, 74%)',
  darkBlue: 'hsl(219, 34%, 46%)',
  subGrey: 'hsl(0, 0%, 60%)',
  basicText: 'hsl(0, 100%, 0%)',
  basicBox: 'hsl(0, 0%, 100%)',
  highlightedBox: 'hsl(203, 100%, 43%)',
  highlightedText: 'hsl(0, 100%, 100%)'
};

const boxShadow = '0 10px 25px hsla(0, 98%, 75%, 0.5)';

const fonts = {
  Main: 'Open Sans',
  Secondary: 'Changa One'
};

const fontSizes = {
  base: '16px',
  primary: '1rem',
  bigHeading: '2.5rem',
  boxHeading: '1.6rem',
  boxPrice: '1.3rem',
  boxPriceSub: '0.8rem',
  boxButton: '1.2rem'
};

const theme = {
  colors,
  boxShadow,
  fonts,
  fontSizes
};

export default theme;
