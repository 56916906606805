export const dashboard = {
  report: []
};

export const subscription = {
  customerId: localStorage.getItem('customerId'),
  subscriptionId: localStorage.getItem('subscriptionId'),
  subscriptionDetails: null,
  user: null,
  dailyQuantity:
    new Date().getDay() - new Date(parseInt(localStorage.getItem('lastDailyUsage') || '0')).getDay() === 0
      ? parseInt(localStorage.getItem('dailyQuantity') || '0')
      : 0
};
