import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faSignInAlt, faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { Nav, Navbar, Dropdown, Container, Button } from '@themesberg/react-bootstrap';
import { get } from 'lodash';
import { useAuth0 } from '@auth0/auth0-react';
import { setCustomerId, setSubscriptionId, setSubscriptionDetails, setSubscriptionRedux } from 'state/actions/checkout';
import { useSelector, useDispatch } from 'react-redux';
import { Routes } from 'routesLinks';
import history from 'utils/history';

const HeaderComponent = () => {
  const subscription = useSelector((state: any) => state.subscription);
  const { logout, user, isAuthenticated, isLoading, loginWithPopup } = useAuth0();
  const dispatch = useDispatch();
  const logoutUser = () => {
    dispatch(setSubscriptionRedux('CLEAR_REDUX', null, null));
    logout({
      returnTo: window.location.origin
    });
  };
  return (
    <Navbar bg='dark' variant='dark' className='justify-content-between'>
      <Navbar.Brand
        href='/'
        className='pl-5'
        style={{
          paddingLeft: '20px',
          zIndex: 1
        }}
      >
        Cybrog
      </Navbar.Brand>
      <Navbar.Toggle aria-controls='basic-navbar-nav' />
      <Navbar.Collapse id='basic-navbar-nav'>
        <Nav className='mr-auto'>
          <Nav.Link href='/'>Home</Nav.Link>
          {!isAuthenticated && <Nav.Link href='/pricing'>Pricing</Nav.Link>}
          {isAuthenticated ? (
            <>
              <Nav.Link href='/dashboard'>Dashboard</Nav.Link>
              <Nav.Link href='/pricing'>Pricing</Nav.Link>
              <Nav.Link href='/profile'>Profile</Nav.Link>

              <Button onClick={logoutUser}>
                <FontAwesomeIcon icon={faSignOutAlt} className='text-danger me-2' /> Logout
              </Button>
            </>
          ) : (
            <>
              <Button
                onClick={() =>
                  loginWithPopup({
                    screen_hint: 'signup'
                  })
                }
                style={{
                  marginRight: '1rem'
                }}
              >
                <FontAwesomeIcon icon={faPlusSquare} className='text-white ml-2' /> Signup
              </Button>
              <Button
                onClick={loginWithPopup}
                style={{
                  marginRight: '1rem'
                }}
              >
                <FontAwesomeIcon icon={faSignInAlt} className='text-white ml-2' /> Login
              </Button>
            </>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default HeaderComponent;
