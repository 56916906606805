import { subscription as initialState } from 'state/reducers/initialState';
import { SET_CUSTOMER_ID, CLEAR_REDUX, SET_SUBSCRIPTION_ID, SET_SUBSCRIPTION_DETAILS, SET_USER_DETAILS } from 'state/types';
import { IAction } from 'state/types';

function reducer(state = initialState, action: IAction) {
  switch (action.type) {
    case SET_CUSTOMER_ID:
      return {
        ...state,
        [action.payload.key]: action.payload.value
      };

    case SET_SUBSCRIPTION_ID:
      return {
        ...state,
        [action.payload.key]: action.payload.value
      };

    case SET_SUBSCRIPTION_DETAILS:
      return {
        ...state,
        [action.payload.key]: action.payload.value
      };
    case SET_USER_DETAILS:
      return {
        ...state,
        [action.payload.key]: action.payload.value
      };

    case CLEAR_REDUX:
      localStorage.getItem('priceId') && localStorage.removeItem('priceId');
      localStorage.getItem('customerId') && localStorage.removeItem('customerId');
      localStorage.getItem('subscriptionId') && localStorage.removeItem('subscriptionId');
      return {
        ...initialState
      };
    default:
  }
  return state;
}

export default reducer;
