import { SET_CUSTOMER_ID, SET_SUBSCRIPTION_ID, SET_SUBSCRIPTION_DETAILS } from 'state/types';

export const setCustomerId = (key, value) => ({
  type: SET_CUSTOMER_ID,
  payload: { key, value }
});

export const setSubscriptionId = (key, value) => ({
  type: SET_SUBSCRIPTION_ID,
  payload: { key, value }
});

export const setSubscriptionDetails = (key, value) => ({
  type: SET_SUBSCRIPTION_DETAILS,
  payload: { key, value }
});

export const setSubscriptionRedux = (type, key, value) => ({
  type: type,
  payload: { key, value }
});
