import { dashboard as initialState } from 'state/reducers/initialState';
import { GET_REPORT, CLEAR_REDUX } from 'state/types';
import { IAction } from 'state/types';

function reducer(state = initialState, action: IAction) {
  switch (action.type) {
    case GET_REPORT.FULLFILLED:
      return {
        ...state,
        report: action.payload,
        activeTab: 'report'
      };
    case CLEAR_REDUX:
      return {
        ...initialState
      };
    default:
  }
  return state;
}

export default reducer;
