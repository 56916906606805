import React from 'react';
import { Provider } from 'react-redux';
import configureStore from 'state/configureStore';
import history from 'utils/history';
import { Router } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
// import hmr from 'utils/hmr';
import MainApp from './MainApp';
import Navbar from 'components/Common/Header';
import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'axios-progress-bar/dist/nprogress.css';
import { CloseButton, Fade } from 'components/Common/Toast';
import { loadProgressBar } from 'axios-progress-bar';
import styled, { ThemeProvider } from 'styled-components';
import { Auth0Provider } from '@auth0/auth0-react';
import { getConfig } from './configAuth.js';
import theme from './theme';

const onRedirectCallback = appState => {
  history.push(appState && appState.returnTo ? appState.returnTo : window.location.pathname);
};

const store = configureStore();
loadProgressBar();

const config = getConfig();

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  // ...(config.audience ? { audience: config.audience } : null),
  redirectUri: window.location.origin,
  onRedirectCallback
};

const App: React.FC = () => {
  return (
    <Auth0Provider {...providerConfig}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Navbar />
          <Router history={history}>
            <MainApp />
          </Router>
          <ToastContainer transition={Fade} closeButton={<CloseButton />} position={toast.POSITION.BOTTOM_LEFT} />
        </ThemeProvider>
      </Provider>
    </Auth0Provider>
  );
};

// export default hmr(module, App);
export default App;
