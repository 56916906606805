import { API } from 'api';
import getRoute from 'api/apiRoutes';

class AppApi extends API {
  getReport = (question: string): Promise<any> => {
    const route = getRoute('getQuestionResult');
    return this.postRequest(route, { question });
  };

  updateUsage = (quantity: number, subscriptionItemId: any, email: any): Promise<any> => {
    const route = getRoute('updateUsage');
    return this.postRequest(route + `?subscriptionItemId=${subscriptionItemId}`, { quantity, email });
  };
  getUsage = (subscriptionId: any): Promise<any> => {
    const route = getRoute('getUsage');
    return this.fetch(route + `?subscriptionItemId=${subscriptionId}`);
  };
  cancelSubscription = (subscriptionId: any): Promise<any> => {
    const route = getRoute('cancelSubscription');
    return this.deleteRequest(route + `?subscriptionId=${subscriptionId}`);
  };
  getPlans = (): Promise<any> => {
    const route = getRoute('getPlans');
    return this.fetch(route);
  };
  login = (email: String, name: String): Promise<any> => {
    const route = getRoute('userLogin');
    return this.postRequest(route, { email, name });
  };
  checkoutSession = (priceId: String, email: String): Promise<any> => {
    const route = getRoute('getCheckoutUrl');
    return this.postRequest(route, { priceId, email });
  };
  checkoutSessionDetails = (sessionId: String, email: String): Promise<any> => {
    const route = getRoute('checkoutSessionDetails');
    return this.postRequest(route + `?sessionId=${sessionId}`, { email });
  };
}

export default new AppApi();
